import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase-config';

function AdvisoryBoardMembers() {

    const [editor, setEditor] = useState([])

    useEffect(() => {
        getEditor()
    }, [])

    function getEditor() {
        const editorRef = collection(db, 'editorial-board')
        getDocs(editorRef)
            .then(response => {
                const ed = response.docs.map(doc => ({
                    data: doc.data(),
                    id: doc.id
                }))
                setEditor(ed)
            })
            .catch(error => console.log(error.message))
    }


    return (
        <div>
            <section id="team" className="team">

                <div className="container" data-aos="fade-up">
                    <div className="section-title" data-aos="fade-up">
                        <h2>Editorial Advisory Board Members</h2>
                    </div>
                    <div className="row gy-4">
                        {editor.map((ed, key) =>
                            <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                                <div className="member">
                                    <div className="member-img">
                                        <img src={ed.data.profile_pic} className="img-fluid" alt="" />
                                        <div className="social">
                                            <a href=""><i className="bx bxl-twitter"></i></a>
                                            <a href=""><i className="bx bxl-facebook"></i></a>
                                            <a href=""><i className="bx bxl-instagram"></i></a>
                                            <a href="" target="_blank"><i className="bx bxl-linkedin"></i></a>
                                        </div>
                                    </div>
                                    <div className="member-info">
                                        <h4>{ed.data.name}</h4>
                                        <span>{ed.data.description}</span>
                                    </div>
                                </div>
                            </div>)}
                    </div>

                </div>

            </section>
        </div>
    )
};

export default AdvisoryBoardMembers;