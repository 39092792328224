import React from 'react';

function PageNotFound() {
    return (
        <div>
            <section id="hero" className="d-flex align-items-center">
                <div className="container" data-aos="zoom-out" data-aos-delay="100">
                    <h1>We're Sorry! <span>Seems the page doesn't exist!</span></h1>
                    <h2>Pharmaceutical Research Foundation</h2>
                    <a href="/" className="btn-get-started scrollto">Take me Home</a>
                </div>
            </section>
            
        </div>
    )
};

export default PageNotFound;