import React from 'react';

function ContactUs() {
    return (
        <div>
            <section id="hero" className="d-flex align-items-center">
                <div className="container" data-aos="zoom-out" data-aos-delay="100">
                    <h1>We're here. <span>Let's Talk.</span></h1>
                    <h2>Pharmaceutical Research Foundation</h2>
                    <div className="d-flex">
                        <a href="#" className="btn-get-started scrollto">Leave Us a Feedback</a>
                        <a href="#" className="glightbox btn-watch-video"><i className="bi bi-play-circle"></i><span>Write us a Mail</span></a>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default ContactUs;