import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"


import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import Japr from './japr/Japr';
import CopyrightTransfer from './japr/CopyrightTransfer';
import SubmitArticle from './japr/SubmitArticle';
import JaprArchive from './japr/Archive';
import ReadArchive from './japr/ReadArchive';
import JaprLatestIssue from './japr/LatestIssue';
import JaprIndexing from './japr/Indexing';
import ContactUs from './ContactUs';
import EditorialBoard from './japr/EditorialBoard';
import PageNotFound from './PageNotFound';

function App() {
  return (
    <div>
      <Header></Header>
      <Router>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/japr" element={<Japr />} />
          <Route path="/japr/copyright-transfer" element={<CopyrightTransfer />} />
          <Route path="/japr/submit-article" element={<SubmitArticle />} />
          <Route path="/japr/archive" element={<JaprArchive />} />
          <Route path="/japr/archive/latest" element={<JaprLatestIssue />} />
          <Route path="/japr/:release" element = {<ReadArchive />} />
          <Route path="/japr/indexing" element={<JaprIndexing />} />
          <Route path="/japr/editorial-board" element={<EditorialBoard />} />
          <Route path="/contact-us" element={<ContactUs/>} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </Router>
      <Footer></Footer>
    </div>
  );
}

export default App;
