import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { Table, Badge } from 'react-bootstrap';
import { useParams } from "react-router-dom";

function ReadArchive(props) {

    const params = useParams()
    const [article, setArticle] = useState([])
    const [issue, setIssue] = useState([])

    useEffect(() => {
        getArticle()
    }, [])

    useEffect(() => {
        getIssue()
    }, [])


    function getArticle() {
        const issueCollectionRef = collection(db, 'japr', params.release+'/articles')
        getDocs(issueCollectionRef)
            .then(response => {
                const art = response.docs.map(doc => ({
                    data: doc.data(),
                    id: doc.id
                }))
                setArticle(art)
            })
            .catch(error => console.log(error.message))
    }

    function getIssue() {
        const issueRef = doc(db, 'japr', params.release)
        getDoc(issueRef).then(snapshot => setIssue(snapshot.data()))
    }


    return (
        <div>
            <section id="hero" className="d-flex align-items-center">
                <div className="container" data-aos="zoom-out" data-aos-delay="100">
                    <h1>JAPR - <span>{issue.name}</span></h1>
                    <h2>{issue.description}</h2>
                    <a href="/japr/archive" className="btn-get-started scrollto">JAPR Archive</a>
                </div>
            </section>

            <div className="padding">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Article ID</th>
                            <th>Title</th>
                            <th>Author(s)</th>
                            <th>Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        {article.map((art, key) => <tr><td>{art.id}</td><td><strong>{art.data.title}</strong></td><td>{art.data.authors}</td><td><a href={art.data.storage_location} target="_blank">View Article</a></td></tr>)}
                    </tbody></Table>
            </div>

        </div>
    )
};

export default ReadArchive;