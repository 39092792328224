import React from 'react';

function AssociateEditors() {
    return (
        <div>
            <section id="team" className="team">

                <div className="container" data-aos="fade-up">
                    <div className="section-title" data-aos="fade-up">
                        <h2>Associate Editors</h2>
                        <p>Our team of Associate Editors</p>
                    </div>

                    <div className="row gy-4">

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                            <div className="member">
                                <div className="member-img">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/pharmres-foundation.appspot.com/o/editorial-board%2Fdebashisha.jpeg?alt=media&token=1b46cf49-549d-488c-8a58-193ef7ab5e2b" className="img-fluid" alt="" />
                                    <div className="social">
                                        <a href=""><i className="bx bxl-twitter"></i></a>
                                        <a href=""><i className="bx bxl-facebook"></i></a>
                                        <a href=""><i className="bx bxl-instagram"></i></a>
                                        <a href="https://www.linkedin.com/in/debashisha/" target="_blank"><i className="bx bxl-linkedin"></i></a>
                                    </div>
                                </div>
                                <div className="member-info">
                                    <h4>Dr.Debashisha Panda</h4>
                                    <span>M.Pharm., Ph.D.</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                            <div className="member">
                                <div className="member-img">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/pharmres-foundation.appspot.com/o/editorial-board%2Fsatya.jpeg?alt=media&token=dbaa1ac5-59af-4671-86bb-fb99da8e2c10" className="img-fluid" alt="" />
                                    <div className="social">
                                        <a href=""><i className="bx bxl-twitter"></i></a>
                                        <a href=""><i className="bx bxl-facebook"></i></a>
                                        <a href=""><i className="bx bxl-instagram"></i></a>
                                        <a href=""><i className="bx bxl-linkedin"></i></a>
                                    </div>
                                </div>
                                <div className="member-info">
                                    <h4>Dr.Satyanarayan Pattnaik</h4>
                                    <span>M.Pharm., Ph.D.</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
                            <div className="member">
                                <div className="member-img">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/pharmres-foundation.appspot.com/o/editorial-board%2Fsambit.jpeg?alt=media&token=9a932401-3275-4145-8801-00927dbbe9f5" className="img-fluid" alt="" />
                                    <div className="social">
                                        <a href=""><i className="bx bxl-twitter"></i></a>
                                        <a href=""><i className="bx bxl-facebook"></i></a>
                                        <a href=""><i className="bx bxl-instagram"></i></a>
                                        <a href=""><i className="bx bxl-linkedin"></i></a>
                                    </div>
                                </div>
                                <div className="member-info">
                                    <h4>Dr.Sambit Parida</h4>
                                    <span>M.Pharm., Ph.D.</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
                            <div className="member">
                                <div className="member-img">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/pharmres-foundation.appspot.com/o/editorial-board%2Fsantanu.jpeg?alt=media&token=3390ff83-25b9-4f15-ab27-c56ff88b10bc" className="img-fluid" alt="" />
                                    <div className="social">
                                        <a href=""><i className="bx bxl-twitter"></i></a>
                                        <a href=""><i className="bx bxl-facebook"></i></a>
                                        <a href=""><i className="bx bxl-instagram"></i></a>
                                        <a href=""><i className="bx bxl-linkedin"></i></a>
                                    </div>
                                </div>
                                <div className="member-info">
                                    <h4>Dr.Santanu Chakraborty</h4>
                                    <span>M.Pharm., Ph.D.</span>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </section>
        </div>
    )
};

export default AssociateEditors;