import React from 'react';

function Japr() {
    return (
        <div>
            <section id="hero" className="d-flex align-items-center">
                <div className="container" data-aos="zoom-out" data-aos-delay="100">
                    <h1>Introducing <span>Journal of Advanced Pharmaceutical Research (JAPR)</span></h1>
                    <h2>Pharmaceutical Research Foundation</h2>
                    <div className="d-flex">
                        <a href="/japr/archive" className="btn-get-started scrollto">Journal Archive</a>
                        <a href="/japr/copyright-transfer" className="glightbox btn-watch-video"><i className="bi bi-play-circle"></i><span>Copyright Transfer</span></a>
                    </div>
                </div>
            </section>
            <section id="about" className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                            <h2>The <strong>Journal of Advanced Pharmaceutical Research (JAPR)</strong> provides rapid publication of articles in all areas of Pharmaceutical Sciences. The Journal welcomes the submission of manuscripts (original research articles and review articles) that meet the general criteria of significance and scientific excellence. Papers will be published quickly after acceptance. JAPR is a quarterly open access publication and all articles are peer-reviewed.</h2>
                            <h3>Instruction for authors and other details are available on our website www.pharmresfoundation.in/japr<br/><br/>Prospective authors should send their manuscript(s) to editor.japr@gmail.com.</h3>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 about-img" data-aos="fade-left" data-aos-delay="200">
                            <img height="450vh" width="450vh" src="assets/japr_booklet.svg"  alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section id="about" className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                            <h1>Open-Access</h1>
                            <p>One key request of researchers across the world is unrestricted access to research publications. JAPR is fully committed Open Access Initiative by providing free access to all articles (both abstract and full PDF text) as soon as they are published.</p>
                            <br/><br/><h1>Publication Alert</h1>
                            <p>We will be glad to send you a publication alert showing the table of content with link to the various abstracts and full PDF text of articles published in each issue. Kindly send us an email if you will like to receive publication alert.<br/>Please share these details with your colleagues who may be interested.</p>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 about-img" data-aos="fade-left" data-aos-delay="200">
                        <h1>Publication Fees</h1>
                            <p>Every article will be charged a nominal cost towards processing, secured data storage system, etc. (Rs.1000 for submissions from India and 50 USD for submissions from outside India).</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default Japr;