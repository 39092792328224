import React from 'react';

function CopyrightTransfer() {
    return (
        <div>
            <section id="hero" className="d-flex align-items-center">
                <div className="container" data-aos="zoom-out" data-aos-delay="100">
                    <h1>Copyright Transfer for <span>JAPR</span></h1>
                    <h2>Pharmaceutical Research Foundation</h2>
                    <a href="/japr" className="btn-get-started scrollto">JAPR Home</a>
                </div>
            </section>

            <div>
                <object data="https://firebasestorage.googleapis.com/v0/b/pharmres-foundation.appspot.com/o/copyright.pdf?alt=media&token=cabef9fa-5967-4a33-b6a4-c64bbabcd182" type="application/pdf" width="100%" height="550vh"></object>
            </div>
            
        </div>
    )
};

export default CopyrightTransfer;