import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase-config';

function Archive() {

    const [issues, setIssues] = useState([])

    useEffect(() => {
        getIssues()
    }, [])

    function getIssues() {
        const issueCollectionRef = collection(db, 'japr')
        getDocs(issueCollectionRef)
            .then(response => {
                const iss = response.docs.map(doc => ({
                    data: doc.data(),
                    id: doc.id
                }))
                setIssues(iss)
            })
            .catch(error => console.log(error.message))
    }

    return (
        <div>
            <section id="hero" className="d-flex align-items-center">
                <div className="container" data-aos="zoom-out" data-aos-delay="100">
                    <h1>Archive - <span>JAPR</span></h1>
                    <h2>Pharmaceutical Research Foundation</h2>
                    <a href="/japr" className="btn-get-started scrollto">JAPR Home</a>
                </div>
            </section>

            <section id="services" className="services">
                <div className="container">
                    <div className="row">
                        {issues.map((issue, key) =>

                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 d-flex align-items-stretch mb-5 mb-lg-0 padding-top">
                                <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                                    <div className="icon"><i className="bx bxs-book-content"></i></div>
                                    <h4 className="title"><a href={issue.id}>Volume: {issue.data.volume} Issue: {issue.data.issue}<br/>{issue.data.description}</a></h4>
                                </div>
                            </div>

                        )}
                    </div>
                </div>
            </section>
        </div>
    )
};

export default Archive;