import React from 'react';

function SubmitArticle() {
    return (
        <div>
            <section id="hero" className="d-flex align-items-center">
                <div className="container" data-aos="zoom-out" data-aos-delay="100">
                    <h1>Submit Article for <span>JAPR</span></h1>
                    <h2>Pharmaceutical Research Foundation</h2>
                    <a href="/japr" className="btn-get-started scrollto">JAPR Home</a>
                    <br/><br/><h2>We are currently not accepting new articles, please reach back for updates!</h2>
                </div>
            </section>
        </div>
    )
};

export default SubmitArticle;