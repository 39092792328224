import React from 'react';

function Footer() {
    return (
        <footer id="footer">
            <div className="footer-newsletter">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <h4>Join Our Editorial Board</h4>
                            <p>Reach us at support@pharmresfoundation.in to know more!</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-top">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-md-6 footer-contact">
                            <h3>PharmRes Foundation<span>.</span></h3>
                            <p>
                            Discover, Invent and Make in India<span>.</span><br/>
                                    Founded in 2010<span>.</span><br/>
                                    Celebrating 12 Years of Innovation & Research<span>.</span> <br/><br/>
                                                <strong>Email:</strong> pharmresfoundation@gmail.com<br/>
                                                </p>
                                            </div>

                                            <div className="col-lg-3 col-md-6 footer-links">
                                                <h4>Useful Links</h4>
                                                <ul>
                                                    <li><i className="bx bx-chevron-right"></i> <a href="http://www.freepatentsonline.com/" target="_blank">Free Patent Search</a></li>
                                                    <li><i className="bx bx-chevron-right"></i> <a href="http://www.uspto.gov/" target="_blank">US Patent and Trademark Office</a></li>
                                                    <li><i className="bx bx-chevron-right"></i> <a href="http://www.fda.gov/" target="_blank">US FDA</a></li>
                                                    <li><i className="bx bx-chevron-right"></i> <a href="http://www.fda.gov/Drugs/default.htm" target="_blank">Center for Drug Evaluation and Research</a></li>
                                                    <li><i className="bx bx-chevron-right"></i> <a href="http://www.accessdata.fda.gov/scripts/cder/ob/default.cfm" target="_blank">Orange Book: Search APIs and Patents</a></li>
                                                    <li><i className="bx bx-chevron-right"></i> <a href="http://wame.org/resources/ethics-resources" target="_blank">Publication Ethics for Medical Journals</a></li>
                                                </ul>
                                            </div>

                                            <div className="col-lg-3 col-md-6 footer-links">
                                                <h4>Quick Links</h4>
                                                <ul>
                                                    <li><i className="bx bx-chevron-right"></i> <a href="/japr/editorial-board">Editorial Board</a></li>
                                                    <li><i className="bx bx-chevron-right"></i> <a href="/japr/archive/latest">Latest Issue</a></li>
                                                    <li><i className="bx bx-chevron-right"></i> <a href="/japr/archive">Journal Archive</a></li>
                                                    <li><i className="bx bx-chevron-right"></i> <a href="/japr">Author Instruction</a></li>
                                                    <li><i className="bx bx-chevron-right"></i> <a href="/japr/submit-article">Submit Article</a></li>
                                                </ul>
                                            </div>

                                            <div className="col-lg-3 col-md-6 footer-links">
                                                <h4>Connect with Us</h4>
                                                <p>We are active on LinkedIn, Twitter and Facebook<span>.</span></p>
                                                <div className="social-links mt-3">
                                                    <a href="#" target="_blank" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                                                    <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                                                    <a href="https://www.facebook.com/PharmResFoundation" target="_blank" className="facebook"><i className="bx bxl-facebook"></i></a>
                                                </div>
                                            </div>

                                        </div>
                                        </div>
                                    </div>

                                    <div className="container py-4">
                                        <div className="copyright">
                                            &copy; Copyright <strong><span>Pharmaceutical Research Foundation</span></strong>. All Rights Reserved
                                        </div>
                                        <div className="credits">
                                            Designed by <a href="https://rahulpanda.in" target="_blank">Rahul Panda</a>
                                        </div>
                                    </div>
                                </footer>

);}

export default Footer;