import React from 'react';
import AssociateEditors from './AssociateEditors';
import ChiefEditor from './ChiefEditor';
import AdvisoryBoardMembers from './AdvisoryBoardMembers';

function EditorialBoard() {
    return (
        <div>
            <section id="hero" className="d-flex align-items-center">
                <div className="container" data-aos="zoom-out" data-aos-delay="100">
                    <h1>JAPR - <span>Editorial Board</span></h1>
                    <h2>Pharmaceutical Research Foundation</h2>
                    <div className="d-flex">
                        <a href="/japr/archive" className="btn-get-started scrollto">Journal Archive</a>
                    </div>
                </div>
            </section>
            <ChiefEditor></ChiefEditor>
            <AssociateEditors></AssociateEditors>
            <AdvisoryBoardMembers></AdvisoryBoardMembers>
        </div>
    )
};

export default EditorialBoard;