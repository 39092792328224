import React from 'react';

function Indexing() {
    return (
        <div>
            <section id="hero" className="d-flex align-items-center">
                <div className="container" data-aos="zoom-out" data-aos-delay="100">
                    <h1>Indexing for <span>JAPR</span></h1>
                    <h2>Pharmaceutical Research Foundation</h2>
                    <a href="/japr" className="btn-get-started scrollto">JAPR Home</a>
                </div>
            </section>
            <section id="about" className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                            <h4>Journal of Advanced Pharmaceutical Research is indexed in the following reputed Bibliographic Publications:</h4>
                            <ul>
                                <i className="bx bx-chevron-right"></i> Chemical Abstracts (CAS), USA<br/>
                                <i className="bx bx-chevron-right"></i> Index Copernicus, Poland <br/>
                                <i className="bx bx-chevron-right"></i> Directory of Open Access Journals (DOAJ), Netherlands <br/>
                                <i className="bx bx-chevron-right"></i> Ulrich's web, USA <br/>
                            </ul>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 about-img" data-aos="fade-left" data-aos-delay="200">
                            <h4>Journal of Advanced Pharmaceutical Research is planned to be indexed in the following reputed Bibliographic Publications:</h4>
                            <ul>
                                <i className="bx bx-chevron-right"></i> Science Citation Index<br/>
                                <i className="bx bx-chevron-right"></i> Journal Citation Reports/Science Edition <br/>
                                <i className="bx bx-chevron-right"></i> International Pharmaceutical Abstract <br/>
                                <i className="bx bx-chevron-right"></i> SCOPUS/Elsevier <br/>
                                <i className="bx bx-chevron-right"></i> MEDLINE <br/>
                                <i className="bx bx-chevron-right"></i> Embase <br/>
                                <i className="bx bx-chevron-right"></i> Bioline International <br/>
                                <i className="bx bx-chevron-right"></i> Open-J-Gate <br/>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default Indexing;