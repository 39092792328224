import React from 'react';

function ChiefEditor() {
    return (
        <div>
            <section id="team" className="team">

                <div className="container" data-aos="fade-up">
                    <div className="section-title" data-aos="fade-up">
                        <h2>Editor-In-Chief</h2>
                    </div>

                    <div className="row gy-4">

                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                            <div className="member">
                                <div className="member-img">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/pharmres-foundation.appspot.com/o/editorial-board%2FDr_Subrat_Mallik.jpeg?alt=media&token=9f3a40e4-7548-4515-adc3-5417804cf1a6" className="img-fluid" alt="" />
                                    <div className="social">
                                        <a href=""><i className="bx bxl-twitter"></i></a>
                                        <a href=""><i className="bx bxl-facebook"></i></a>
                                        <a href=""><i className="bx bxl-instagram"></i></a>
                                        <a href="" target="_blank"><i className="bx bxl-linkedin"></i></a>
                                    </div>
                                </div>
                                <div className="member-info">
                                    <h4>Prof (Dr.) Subrata Mallick</h4>
                                    <span>M.Pharm., Ph.D., PGDBM, FIC</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
        </div>
    )
};

export default ChiefEditor;