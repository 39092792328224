import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { Table, Badge } from 'react-bootstrap';

function LatestIssue() {

    const [article, setArticle] = useState([])
    const [issue, setIssue] = useState([])

    useEffect(() => {
        getArticle()
    }, [])

    useEffect(() => {
        getIssue()
    }, [])


    function getArticle() {
        const issueCollectionRef = collection(db, 'japr', 'volume-8-issue-1/articles')
        getDocs(issueCollectionRef)
            .then(response => {
                const art = response.docs.map(doc => ({
                    data: doc.data(),
                    id: doc.id
                }))
                setArticle(art)
            })
            .catch(error => console.log(error.message))
    }

    function getIssue() {
        const issueRef = doc(db, 'japr', 'volume-8-issue-1')
        getDoc(issueRef).then(snapshot => setIssue(snapshot.data()))
    }


    return (
        <div>
            <section id="hero" className="d-flex align-items-center">
                <div className="container" data-aos="zoom-out" data-aos-delay="100">
                    <h1>Introducing <span>Journal of Advanced Pharmaceutical Research (JAPR)</span></h1>
                    <h2>Pharmaceutical Research Foundation</h2>
                    <a href="/japr" className="btn-get-started scrollto">JAPR Home</a>
                </div>
            </section>

            <div className="padding">
                <h3><Badge bg="success">Release:</Badge> {issue.name} &nbsp;&nbsp;&nbsp; <Badge bg="success">Month: </Badge> {issue.description}</h3><br/>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Article ID</th>
                            <th>Title</th>
                            <th>Author(s)</th>
                            <th>Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        {article.map((art, key) => <tr><td>{art.id}</td><td><strong>{art.data.title}</strong></td><td>{art.data.authors}</td><td>{art.data.storage_location}</td></tr>)}
                    </tbody></Table>
            </div>

        </div>
    )
};

export default LatestIssue;