// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCIMKWyKFZx8QQtSPMiA8y7gJKzgMQHUT0",
  authDomain: "pharmres-foundation.firebaseapp.com",
  projectId: "pharmres-foundation",
  storageBucket: "pharmres-foundation.appspot.com",
  messagingSenderId: "325717066672",
  appId: "1:325717066672:web:038db8e6ce3badf60b8c75",
  measurementId: "G-G74S1V3PLL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);