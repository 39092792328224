import React from 'react';
import { Navbar, NavDropdown, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Header() {
    return (
        <div>
            <section id="topbar" class="d-flex align-items-center">
                <div class="container d-flex justify-content-center justify-content-md-between">
                    <div class="contact-info d-flex align-items-center">
                        <span><strong>e-ISSN:</strong> 2229-3787</span>
                    </div>
                    <div class="social-links d-none d-md-flex align-items-center">
                    <a href="#" target="_blank" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                                                    <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                                                    <a href="https://www.facebook.com/PharmResFoundation" target="_blank" className="facebook"><i className="bx bxl-facebook"></i></a>
                    </div>
                </div>
            </section>
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand href="/">PharmaResFoundation</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <NavDropdown title="JAPR" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/japr">Author Instruction</NavDropdown.Item>
                                <NavDropdown.Item href="/japr/archive">Archive</NavDropdown.Item>
                                <NavDropdown.Item href="/japr/indexing">Indexing</NavDropdown.Item>
                                <NavDropdown.Item href="/japr/copyright-transfer">Copyright Transfer</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/japr/archive">Journal Archive</Nav.Link>
                            <Nav.Link href="/japr/archive/latest">Latest Issue</Nav.Link>
                            <Nav.Link href="/japr/editorial-board">Editorial Board</Nav.Link>
                            <Nav.Link href="/japr/submit-article">Submit Article</Nav.Link>
                            <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default Header;