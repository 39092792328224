import React from 'react';
import AssociateEditors from './japr/AssociateEditors';

function Home() {
    return (
        <div>
            <section id="hero" className="d-flex align-items-center">
                <div className="container" data-aos="zoom-out" data-aos-delay="100">
                    <h1>Welcome to <span>Pharmaceutical Research Foundation</span></h1>
                    <h2>Horizon for Future Research!</h2>
                    <div className="d-flex">
                        <a href="/japr" className="btn-get-started scrollto">Journal of Advanced Pharmaceutical Research</a>
                    </div>
                </div>
            </section>
            <section id="about" className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                            <h1><span>About Us</span></h1>
                            <h2><strong>Pharmaceutical Research Foundation</strong>, established in 2010, is aimed at each and every possible effort to establish a profound platform for redefining the structure of Pharmacy Profession Worldwide by giving more and more emphasis on development of overall scientific knowledge, providing career support to the upcoming professional in the field of Pharmacy and allied health sciences, acting as a medium for scientific knowledge exchange process among various community, encouraging research activities and recognizing outstanding research contributions in the field of Pharmaceutical and allied health sciences, providing educational support to Pharmacy and allied health sciences students through creation of educational institutions, etc.</h2>
                            <div>
                                <a href="/contact-us" className="btn-get-started scrollto">Contact Us</a>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 about-img" data-aos="fade-left" data-aos-delay="200">
                            <img src="assets/about-image.png" className="img-fluid animated" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section id="services" className="services">
                <div className="container">

                    <div className="section-title" data-aos="fade-up">
                        <h2>Most Visited</h2>
                        <p>Explore most visited pages!</p>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                                <div className="icon"><i className="bx bxs-book-content"></i></div>
                                <h4 className="title"><a href="/japr">Author Instruction</a></h4>
                                <p className="description">This helps guide potential authors to construct their article in the correct way, and prepare it for submission.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                                <div className="icon"><i className="bx bx-file"></i></div>
                                <h4 className="title"><a href="/japr/copyright-transfer">Copyright Transfer</a></h4>
                                <p className="description">Assign the rights to publisher in exchange for the privilege of being published.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                                <div className="icon"><i className="bx bx-mail-send"></i></div>
                                <h4 className="title"><a href="/japr/archive/latest">Latest Issue</a></h4>
                                <p className="description">Find the latest issue of Journal of Advanced Pharmaceutical Research.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
                                <div className="icon"><i className="bx bx-dna"></i></div>
                                <h4 className="title"><a href="/japr/archive">Journal Archive</a></h4>
                                <p className="description">Find all the issues of Journal of Pharmaceutical Research Foundation.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <AssociateEditors></AssociateEditors>
        </div>
    );
}

export default Home;